import './styles/main.scss'
import A11yDialog from 'a11y-dialog'
import { reachGoal } from './analytics'

const getScrollBarWidth = function () {
    const el = document.createElement('div')

    el.style.position = 'absolute'
    el.style.top = '-99px'
    el.style.width = '50px'
    el.style.height = '50px'
    el.style.overflow = 'scroll'
    el.style.visibility = 'hidden'

    document.body.appendChild(el)
    const scrollBarWidth = el.offsetWidth - el.clientWidth
    document.body.removeChild(el)

    return scrollBarWidth
}

const disableScrollLock = function () {
    document.body.style.overflow = null
    document.body.style.paddingRight = null
}

const enableScrollLock = function () {
    const body = document.body
    const htmlEl = document.documentElement
    body.style.overflow = 'hidden'

    if (htmlEl.scrollHeight > htmlEl.clientHeight) {
        body.style.paddingRight = `${getScrollBarWidth()}px`
    }
}

document.addEventListener('DOMContentLoaded', () => {
    function offVideos (container) {
        return container
            .querySelectorAll('iframe[data-src^="https://www.youtube"]')
            .forEach(iframe => iframe.removeAttribute('src'))
    }

    function setupVideo (container) {
        return container
            .querySelectorAll('iframe[data-src^="https://www.youtube"]')
            .forEach(iframe => iframe.setAttribute('src', iframe.dataset.src))
    }

    document.body.querySelectorAll('.modal').forEach((container) => {
        // eslint-disable-next-line
        const dialog = new A11yDialog(container)

        dialog.on('show', () => {
            enableScrollLock()
            setupVideo(container)

            if (container.tagName === 'FORM') {
                if (container.querySelector('input[type="text"]')) {
                    container.querySelector('input[type="text"]').focus()
                } else if (container.querySelector('textarea')) {
                    container.querySelector('textarea').focus()
                }
            }
        })

        dialog.on('hide', () => {
            disableScrollLock()
            offVideos(container)

            container.parentElement
                .querySelectorAll('[data-form]')
                .forEach(form => {
                    form.reset()
                    form.querySelector('[data-element="successMessage"]').hidden = true
                    form.querySelector('[data-element="errorMessage"]').hidden = true
                    form.querySelector('[data-element="body"]').hidden = false
                })
        })
    })
})

const initMainMenu = container => {
    const isMobile = () => window.innerWidth < 1084
    const body = document.body
    window.addEventListener('resize', () => {
        if (!isMobile() && document.body.classList.contains('navbar-menu-expanded')) {
            body.classList.remove('navbar-menu-expanded')
        }
    })

    const toggleButton = container.querySelector('.navbar-toggle')
    const toggleButtonTitle = () => {
        toggleButton.title = toggleButton.title === 'Скрыть главное меню' ? 'Показать главное меню' : 'Скрыть главное меню'
        if (isMobile()) {
            body.classList.toggle('navbar-menu-expanded')
        }
    }

    toggleButton.addEventListener('click', evt => {
        evt.preventDefault()
        toggleButtonTitle()
    })

    container.querySelectorAll('.navbar-button').forEach(button => {
        button.addEventListener('click', () => {
            toggleButtonTitle()
        })
    })
}

/* Инициализация главного меню */
document.addEventListener('DOMContentLoaded', function _initMainMenu () {
    document.removeEventListener('DOMContentLoaded', _initMainMenu)

    document.body.querySelectorAll('.navbar').forEach(initMainMenu)
})

document.addEventListener('fullscreenchange', () => {
    const section = document.body.querySelector('[full-screen]')

    if (document.fullscreenElement) {
        section.classList.add('full-screen')
        section.classList.remove('container')
    } else {
        section.classList.remove('full-screen')
        section.classList.add('container')
    }
})

/* Инициализация кнопки скролла вверх */
document.addEventListener('DOMContentLoaded', function _initScrollToTop () {
    document.removeEventListener('DOMContentLoaded', _initScrollToTop)
    const toTopBtn = document.querySelector('.scrollup')
    const OFFSET = 500
    if (!toTopBtn) {
        return
    }
    window.onscroll = function () {
        if (window.pageYOffset > OFFSET) {
            toTopBtn.classList.add('showBtn')
        } else {
            toTopBtn.classList.remove('showBtn')
        }
        toTopBtn.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        })
    }
})

document.addEventListener('DOMContentLoaded', function _initGoals () {
    document.body.querySelectorAll('[data-goal-click]').forEach((element) => {
        element.addEventListener('click', function () {
            reachGoal(element.dataset.goalClick)
        })
    })
})
