export function reachGoal (goal) {
  if (window.console) {
    window.console.info('goal: ' + goal)
  }

  const opts = {
    hitType: 'event',
    eventCategory: 'content',
    eventAction: 'view',
    eventLabel: goal,
    eventValue: 10
  }

  const counters = Object.keys(window).filter((key) => {
    return key.substring(0, 9) === 'yaCounter'
  })

  if (counters.length !== 0) {
    counters.forEach((key) => {
      if (window.console) {
        window.console.info(key + ': ' + goal)
      }
      try {
        window[key].reachGoal(goal)
      } catch (e) {
        if (window.console) {
          window.console.error(e)
        }
      }
    })
  }

  if (window.ga) {
    if (window.console) {
      window.console.info(opts)
    }
    try {
      window.ga('send', opts)
    } catch (e) {
      if (window.console) {
        window.console.error(e)
      }
    }
  }

  return false
}
